<!-- 客户经理 -->
<template>
  <div class="customer_manager">
    <div class="top_card">
      <el-form label-width="100px">
        <el-form-item label="所在地区：">
          <city-select
            ref="child"
            :province="queryInfo.provinces"
            :city="queryInfo.city"
            :district="queryInfo.district"
            @searchProvince="searchProvince"
            @searchCity="searchCity"
            @searchDistrict="searchDistrict"
          />
        </el-form-item>
        <div class="row">
          <div class="btn_list">
            <el-button type="primary" @click="add" :disabled="addBtnDisable">新增</el-button>
            <!-- <el-button plain>批量导入</el-button> -->
          </div>
          <!-- 右边搜索 -->
          <div class="search_right">
            <el-input
              placeholder="请输入姓名/手机号"
              v-model="queryInfo.keys"
              clearable
              class="input-with-select"
            >
              <el-button slot="append" @click="search" type="primary">搜索</el-button>
            </el-input>
          </div>
        </div>
      </el-form>
    </div>
    <div class="row">
      <div class="row_txt">
        <span>共</span>
        <span class="color1">{{ total }}</span>
        <span>个经理</span>
      </div>
      <div class="sort">
        <span @click="handleSort(sortVal)">按更新时间</span>
        <div class="sort_icon">
          <div
            class="triangle_top_box"
            :class="isActive === 'up' ? 'top_active' : ''"
            @click="handleSort('up')"
          ></div>
          <div
            class="triangle_bottom_box"
            :class="isActive === 'down' ? 'bottom_active' : ''"
            @click="handleSort('down')"
          ></div>
        </div>
      </div>
    </div>
    <div class="card_list">
      <div class="card_item" v-for="(item, index) in list" :key="index">
        <div class="login_time">
          <i class="iconfont icon-jr-icon-refresh1 color1"></i>
          <span class="color1">最近登录时间：</span>
          <span class="color1">{{ item.loginTime }}</span>
        </div>
        <div class="card_left">
          <div class="img_box">
            <img :src="item.customerHead" alt="" />
          </div>
          <div class="info_box">
            <div class="manager_name">{{ item.customerName }}</div>
            <div class="row">
              <div class="icon_box">
                <i class="iconfont icon-jr-icon-job color1"></i>
              </div>
              <span>职位：{{ item.customerPosition }}</span>
              <span class="color6">|</span>
              <span>{{ item.sex === 0 ? '男' : '女' }}</span>
              <div class="icon_box">
                <i v-if="item.sex === 1" class="iconfont icon-jr-icon-Woman color4"></i>
                <i v-else class="iconfont icon-jr-icon-Man color5"></i>
              </div>
            </div>
            <div class="row" style="margin-bottom: 0px !important;">
              <div class="icon_box">
                <i class="iconfont icon-jr-icon-area color1"></i>
              </div>
              <div class="txt_row">
                <span>所在区域：</span>
                <!-- <span v-if="item.provinces">{{ item.provinces + '-' }}</span> -->
                <span v-if="item.city">{{ item.city + '-' }}</span>
                <span v-if="item.district">{{ item.district }}</span>
              </div>
              <div class="icon_box">
                <i class="iconfont icon-jr-icon-phone color1"></i>
              </div>
              <span>手机号：{{ item.customerPhone }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <div class="item" @click="toAddCustomerManager(item.id, 1)">
              <div class="num">{{ item.updatedEnterprise ? item.updatedEnterprise : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <i class="iconfont icon-jr-icon-document"></i>
                </div>
                <span class="txt">已更新企业</span>
              </div>
            </div>
            <div class="item" @click="toAddCustomerManager(item.id, 1)">
              <div class="num">
                {{ item.monthlyRenewalEnterprise ? item.monthlyRenewalEnterprise : 0 }}
              </div>
              <div class="row">
                <div class="icon_box">
                  <i class="iconfont icon-jr-icon-calendar"></i>
                </div>
                <span>本月更新企业</span>
              </div>
            </div>
            <div class="item" @click="toAddCustomerManager(item.id, 1)">
              <div class="num">{{ item.affiliatedEnterprise ? item.affiliatedEnterprise : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <i class="iconfont icon-jr-icon-buildings1"></i>
                </div>
                <span>关联企业</span>
              </div>
            </div>
          </div>
          <div class="btn_box">
            <div class="btn_list">
              <!-- <el-button @click="toAddCustomerManager(item.id, 2)">学习中心</el-button> -->
              <span @click="edit(item.id, 1)" class="color1">编辑</span>
              <span @click="setFreeze(item.id)" :class="item.notFreeze ? 'color1' : 'color2'">
                {{ item.notFreeze ? '解冻' : '冻结' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 新增客户经理 -->
    <el-dialog
      title="编辑信息"
      :visible.sync="addDialogVisible"
      :before-close="addDialogClosed"
      :close-on-click-modal="false"
      width="60%"
    >
      <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="头像：">
          <div>
            <img-big-upload
              :imageShow.sync="form.customerHead"
              :addTitleShow="false"
              @removeImg="removeImgImage"
              @uploadOneImgShow="uploadOneImgShowImage"
            />
          </div>
        </el-form-item>
        <el-form-item label="姓名：" prop="customerName">
          <div class="input_box">
            <el-input v-model="form.customerName"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="当前单位：" prop="currentUnit">
          <div class="input_box">
            <el-input v-model="form.currentUnit"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="职位：" prop="customerPosition">
          <div class="input_box">
            <el-input v-model="form.customerPosition"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="性别：">
          <el-radio-group v-model="form.sex">
            <el-radio v-for="item in sexList" :key="item.id" :label="item.id">{{
              item.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="手机号：" prop="customerPhone">
          <div class="input_box">
            <el-input v-model="form.customerPhone"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="所在区域：" prop="city">
          <CitySelect
            ref="childs"
            :province="form.provinces"
            :city="form.city"
            :district="form.district"
            @searchProvince="searchAddProvince"
            @searchCity="searchAddCity"
            @searchDistrict="searchAddDistrict"
          />
        </el-form-item>
      </el-form>
      <div class="dialog_footer">
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="save" :disabled="saveBtnDisable">保存</el-button>
          <el-button @click="addDialogClosed">取 消</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CitySelect from '@/components/CitySelect.vue'
import { listPage, listById, save, update, notFreeze } from '@/api/customerManager.js'

const operatorId = sessionStorage.getItem('operatorId')
const defaultQueryInfo = Object.freeze({
  operatorId,
  pageNum: 1,
  pageSize: 10,
  provinces: '贵州省',
  city: '',
  district: '',
  orders: 1, // 默认传1 2->更新时间降序;3->更新时间升序
  keys: '' //  姓名/电话
})
const defaultForm = Object.freeze({
  operatorId,
  currentUnit: '', // 单位
  city: '', // 城市
  customerHead: '', // 头像
  customerName: '', // 名字
  customerPhone: '', // 电话
  customerPosition: '', // 职位
  // dateOfBirth: '', // 出生年月
  district: '', // 区/县
  notFreeze: false, // 是否冻结(默认0 0->未冻结;1->已冻结)
  provinces: '贵州省', // 省
  sex: 0 // 性别(默认0 0->男;1->女)
})

export default {
  name: 'CustomerManager',
  components: { CitySelect },
  data() {
    return {
      saveBtnDisable: false, //  禁用添加客户经理保存按钮
      addBtnDisable: false, // 禁用添加客户经理按钮
      sortVal: 'up', // 排序点击事件的传参
      isActive: '', // 排序图标样式
      addDialogVisible: false, //新增客户经理
      queryInfo: { ...defaultQueryInfo }, //查询传参
      form: { ...defaultForm }, // 表单
      total: 0, //总条数
      list: [], //数据
      //性别列表
      sexList: [
        {
          id: 0,
          label: '男'
        },
        {
          id: 1,
          label: '女'
        }
      ],
      rules: {
        customerName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        currentUnit: [
          { required: true, message: '请输入所处单位', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        customerPosition: [
          { required: true, message: '请输入职位', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        customerPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, cb) => {
              if (/^1[3-9]\d{9}$/.test(value)) {
                cb()
              } else {
                cb(new Error('手机号格式错误'))
              }
            },
            trigger: 'blur'
          }
        ],
        city: [{ required: true, min: 2, message: '请选择所在区域', trigger: 'blur' }]
      }
    }
  },
  created() {
    // this.search()
  },
  methods: {
    async search() {
      const res = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.list = res.data.list
    
        this.total = res.data.total
        // this.$message.success('获取客户经理信息成功!')
      } else {
        this.$message.warning('获取客户经理信息失败!')
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化 配置智参中心
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.provinces = val
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.district = val
      this.search()
    },
    //排序
    handleSort(val) {
      this.isActive = val
      if (val === 'up') {
        this.queryInfo.orders = 2
        this.sortVal = 'down'
        this.search()
      } else {
        this.queryInfo.orders = 3
        this.sortVal = 'up'
        this.search()
      }
    },
    // 新增客户经理
    add() {
      this.addBtnDisable = true
      this.form = { ...defaultForm }
      this.echo = '0'
      let imgUrl =
        'https://operator-iq-1300257084.cos.ap-shanghai.myqcloud.com/operator-data/corvor.png'
      this.uploadOneImgShowImage(imgUrl)
      this.addDialogVisible = true
    },
    //编辑
    async edit(id, val) {
      this.$router.push({
        name: 'AddCustomerManager',
        query: {
          id,
          val
        }
      })
    },
    //前往详情界面
    toAddCustomerManager(id, val) {
      //根据val区分 '关联企业' 和 '学习中心' 1为关联企业，2为学习中心
      this.$router.push({
        name: 'AddCustomerManager',
        // path: '',
        query: { id, val }
      })
    },
    //冻结、解冻
    async setFreeze(id) {
      const res = await notFreeze({ id })
      if (res.resultCode === 200) {
        this.$message.success('冻结成功')
        this.search()
      } else {
        this.$message.warning('冻结失败!')
      }
    },
    //删除头像
    removeImgImage(val) {
      this.form.customerHead = ''
    },
    //添加头像
    uploadOneImgShowImage(val) {
      this.form.customerHead = val
    },
    //返回省 弹窗
    searchAddProvince(val) {
      this.form.provinces = val
    },
    //返回城市 弹窗
    searchAddCity(val) {
      this.form.city = val
    },
    //返回区/县 弹窗
    searchAddDistrict(val) {
      this.form.district = val
    },
    //保存按钮
    save() {
      this.saveBtnDisable = true
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          const res = await save(this.form)
          if (res.resultCode === 200) {
            this.addDialogVisible = false
            this.$message.success('新增成功!')
            this.addBtnDisable = false
            this.saveBtnDisable = false
            this.search()
            this.$refs.child.clear()
          } else {
            this.$message.warning(res.message)
            this.saveBtnDisable = false
          }
        } else {
          this.$message.warning('请填写必要的信息!')
          this.saveBtnDisable = false
        }
      })
    },
    //新增客户经理弹窗关闭
    addDialogClosed() {
      this.addDialogVisible = false
      this.saveBtnDisable = false
      this.addBtnDisable = false
      this.$refs.child.clear()
      this.form = { ...defaultForm }
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item ::v-deep .el-form-item__label {
  color: #7c7f8e;
}
.el-form-item ::v-deep .el-input {
  width: 100%;
}
.el-form-item ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
  min-height: 40px;
}
.el-form-item__content ::v-deep .upload-demo {
  width: 100px;
}
.el-radio-button ::v-deep .el-radio-button__inner {
  border-radius: 4px !important;
  border: 0 !important;
}
.input_box {
  width: 300px;
}
.search_right {
  width: 460px;
  margin-left: auto;
  margin-right: 20px;
  ::v-deep .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.btn_list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 8px !important;
}
.icon_box {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  img {
    width: 16px;
    height: 16px;
  }
  i {
    font-size: 16px;
  }
}
.sort {
  display: flex;
  align-items: center;
  margin-left: 20px;
  span {
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #595959;
    margin-right: 5px;
  }
  .sort_icon {
    cursor: pointer;
    display: flex;
    flex-flow: column;
    margin-left: 5px;
    i {
      color: #dbd6d6;
      font-size: 10px;
    }
  }
}
.new_page {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border-top: #fafafa 1px solid;
  text-align: center;
}

.top_card {
  display: flex;
  flex-flow: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
  padding-top: 20px;
  box-sizing: border-box;
  .btn_list {
    margin-left: 20px;
  }
}
.customer_manager{
  height: calc(100vh - 340px);
}
.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 340px);
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .card_item {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .login_time {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 12px;
    }
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      .img_box {
        width: 74px;
        height: 74px;
        margin-right: 10px;
        img {
          width: 74px;
          height: 74px;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .manager_name {
          font-weight: bold;
          font-size: 18px;
          color: #000;
          margin-bottom: 8px;
        }
        .row span {
          font-size: 14px;
          margin-right: 10px;
        }
        .row .txt_row {
          margin-right: 20px;
          span {
            margin-right: 0;
          }
        }
      }
    }
    .card_right {
      display: flex;
      margin-left: auto;
      .box_list {
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          .num {
            font-weight: bold;
            font-size: 24px;
            color: #151515;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            color: #151515;
          }
        }
        .item:hover {
          .num {
            color: #448aff;
          }
          .icon_box {
            color: #448aff;
          }
          span {
            color: #448aff;
          }
        }
      }
      .btn_box {
        display: flex;
        align-items: center;
        margin-left: 50px;
        .btn_list {
          ::v-deep .el-button {
            background: #fee1cc;
            border: none;
            color: #fc8b39;
          }
          .el-button,
          span:hover {
            opacity: 0.7;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.color1 {
  color: #4e93fb;
}
.color2 {
  color: #fd5469;
}
.color3 {
  color: #fc8b39;
}
.color4 {
  color: #fd89ff;
}
.color5 {
  color: #0062ff;
}
.color6 {
  color: #dad5d5;
}
</style>
